import type { CursorCollection } from "@smakecloud/smake-use";
import axios from "axios";
import type { ProductionProductionMethod } from "./types/productionProductionMethods";

export async function list(params?: {
    cursor?: string;
    perPage?: number;
    filter?: {
        q?: string;
    };
}) {
    const { data } = await axios.get<
        CursorCollection<ProductionProductionMethod>
    >("production-methods", {
        params: {
            cursor: params?.cursor,
            per_page: params?.perPage,
            filter: {
                q: params?.filter?.q,
            },
        },
    });

    return data;
}

export async function get(id: number) {
    const { data } = await axios.get<ProductionProductionMethod>(
        `production-methods/${id}`,
    );

    return data;
}

export async function activatePresorting(productionProductionMethodId: number) {
    return axios.post(
        `production-methods/${productionProductionMethodId}/presort/activate`,
    );
}

export async function deactivatePresorting(
    productionProductionMethodId: number,
) {
    return axios.post(
        `production-methods/${productionProductionMethodId}/presort/deactivate`,
    );
}
